@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
@import url(//cdn-images.mailchimp.com/embedcode/classic-10_7.css);
/**FONT STYLE*/
.sora{
  font-family: 'Sora', 'Cantarell'; 
}
.sora-bold{
  font-family: 'Sora', 'Cantarell';
  font-weight: bold;
  
}
.z-index{
  z-index: 0;
}
.sora-green{
  font-family: 'Sora','Cantarell';
  font-weight: bold;
  letter-spacing: 0.07m;
}
.sora-green{
  font-family: 'Sora','Cantarell';
  color: #0DA40D;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.sora-green-nospace{
  font-family: 'Sora','Cantarell';
  color: #0DA40D;
  font-weight: bold;
}
.poppins{
  font-family: 'Poppins';
}
.poppins-bold{
  font-family: 'Poppins';
  font-weight: bold;
}
.poppins-medium{
  font-family: 'Poppins';
  font-weight: 300;
}

/*General styles*/
.center-x{
  
  margin: 0px auto;
  display: flex;
  justify-content: center;
  

 
}

.center-y{
  margin:  auto 0px;
  display: flex;
  justify-content: center;
  
}
.center{
  margin: auto 0;
}


.sections{
  width: 100%;
  height: calc(100vh - 90px);
  position: relative;
  top: 90px;

  
}
@media (max-width:1000px){
  .hide{
    display: none;
  }
}

@media (max-width: 521px) {
    
    .sections{
      top: 18vh;
    height: calc(100vh -18vh);
    }
  }

  

  @media (max-width: 429px){ /*redmi, 12pro max*/
    .sections{
      width: 100%;
  height: calc(100vh - 120px);
  position: relative;
  top: 120px;}
  }
  
  .white{
color: white;
  }
a {
  color: #ED7200;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Poppins', 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*intro css file*/

.deck{
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: space-around;
  
/*used for the margin*/  
   
}
/*FEED section*/
.feedCard{
  height: 410px;
  width: 350px;
  border: 1px solid #656565;
  border-radius: 15px;
  margin: 0px 15px;
  display: flex;
  
 
}

.feedCard:hover{
  box-shadow: 2px 2px 10px #00000020;
}
.feedboarder{
  margin: 15px 15px;
  justify-content: center;
}
.feedimg{
  border-radius: 7.5px;
}
.feedLink{
  text-decoration: none;
  color: #000000;
}
.feedLink:hover{
  color: #ED7200;
}

.feedDate{
  color: #959595;
  text-align: right;
}

.feedmargin{
  margin: 15px 0px;
}

.intro-length{
  max-width: 500px;
  min-width: 260px;
  margin-left: 0px;
}
/*testimonial section*/
.testimonialBox{
  max-width: 500px;
  height: 220px;
  background-color: #ED7200;
  border-radius: 15px;
  color: white;

}

/*NOTICE BOARD*/
.noticeBox { /* Our base */
 
  height: -webkit-fit-content;
 
  height: -moz-fit-content;
 
  height: fit-content;
  background-color: #EE790D; /* For browsers that do not support gradients */
  margin: auto 0px;
  padding: 15px 0px;
}

.listStyle {
  list-style-type: square;
  color:  #ffffff;
  
}
/*ADMISSION SECTION*/

.admBox{
  height: 350px;
  width: 300px;
  background-color: #E7EAEE;
  border-radius: 10px;
  margin: 5px 8px;
  

}
.admWidth{
  width: 260px;
  margin-left: 20px;
  margin-top: 60px;
}

.footer-length{
  max-width: 400px;
  min-width: 260px;
  margin-left: 0px;
}

.iconsss{
  display: flex;
  width: 100px;
  justify-content: space-between;
  margin-bottom: 15px;
  
}/*#00a82d*/

/*OWL CAROUSEL*/
.img-margin{
  margin-right: 40px;
}

/*ABOUT US PAGE*/

/*striped background*/
.about-bg{
  width: auto;
  height: auto;
  background:  repeating-linear-gradient(
  55deg,
  #00A82D,
  #00A82D, 60px,
  #0FBC16 10px,
  #0FBC16 120px)
}

/*the small card section "why not join us" & "for more enquires" */

.mini-card{
  background-color: #E7EAEE;
  max-width: 400px;
  height: 220px;
  border-radius: 15px;
  margin: 10px;
}
.mini-card-text{
  max-width: 350px;
  min-width: 200px;
  margin-left: 10px;
  padding-top: 20px;

}


button
{
  background-color: #ED7200;
  padding: 3px 15px;
  color: #ffffff;
  border-style: solid;
  border-color: #ED7200;
 
}
button:hover{
  background-color: #00A82D;
  color: #ffffff;
  border-style: solid;
  border-color: #00A82D;
}
.btntransparent {
 border-radius: 0%;
  background-color: transparent;
  border-style: solid;
  border-color: #ED7200;

  color: rgb(0, 0, 0);
  font-weight: 500;
  
}
.btntransparentwhite {
  border-radius: 0%;
   background-color: transparent;
   border-style: solid;
   border-color: #ED7200;
 
   color: white;
   font-weight: 500;
   
 }

/*ACADEMICS*/

/*CARDS*/
.card-sec{
  background-color: #EDEDED;
  padding:30px 0px;
  
}

.card:hover{
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.138);
  
}

/*CONTACT*/
.contact{
  max-width: 500px;
  min-width: 250px;
}

.contact-icon{
  background-color:#ED7200;
  background-image: linear-gradient(to right, #64FCD9 0%, #EE790D 100%);
  padding: 15px;
  border-radius: 15px;
}

/*GALLERY*/
.gallery{
  height: 100vh;
}

.tour{
  overflow-x: hidden;
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: #F9E0AE;
}

.gal-intro{
  background-color: #00A82D;
      width: 100%;
      height: calc(100vh - 90px);
}
.gal-shade-1{
  background-color: #F9E0AE;
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding: 40px 0px;      
    
}


.gal-shade-2{
  background-color: #FC8621;
      width: 100%;
      height: auto;      
      padding: 30px 0px; 
}

.gal-shade-3{
  background-color: #C24914;
      width: 100%;
      height: auto;  
      padding-top: 30px;    
    padding-bottom: 15px;
}

.gal-shade-4{
  background-color: #682C0E;
      width: 100%;
      height: auto;   
     padding-top: 15px;
     padding-bottom: 90px;
}

.gal-shade-5{
  background-color: #E46F0E;
      width: 100%;
      height: auto;   
     padding-top: 15px;
     padding-bottom: 90px;
}

.vid-width{
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.carousel-text{
  background-color: #ed720088;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
margin: auto;
}



.img-414{
  min-width: 200px;
  max-width: 500px;
}
.img-noticeboard{
  min-width:250px;
  max-width: 500px;
}
/*News sector*/


.news-width{
  max-width: 700px;
  min-width: 180px;

}
.news-bg{
  width: auto;
  height: auto;
  background:  repeating-linear-gradient(
  90deg,
  #cf3b00,
  #cf3b00, 60px,
  #ED7200 10px,
  #ED7200 120px);
  padding: 20px;
}

.news-button{
  height: 60px;
  background-color: #00A82D;
  border: 2px solid white;
  

}

.news-flex{
  display: flex;
}

/*BOARDING SECTION*/

.boarding-img{
  max-width: 500px;
  margin: 10px 0px;
}

.ul-width{
  max-width: 1000px;
  min-width: 200px;
}


/*PAGE NOT FOUND*/
.pageMargin{
  margin: 100px 0px;
}


/*calendar*/
.calendar{
  
  border: solid 1px #777;
}

/**/
.feed{
  display: flex; max-width: 550px; width: 100%; margin-top: 10px; margin-bottom: 10px;
}

.feedkini{
 position: static; visibility: visible; width: 394px; height: 296px; display: block; flex-grow: 1
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

